<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.machineCommand')">
    <div class="row">
      <div class="col-sm-6 col-md-6">
        {{ $t('message.machineId') }} : <br>
        <multi-list-select 
          :list="machineData"
          option-value="machineId"
          option-text="name"
          :selected-items="selectedMachine"
          placeholder="All"
          @select="onSelectMachine">
        </multi-list-select>
      
        
      </div>
<!--
      <div class="col-sm-6 col-md-2">
        ถึง : <br>
        <date-picker 
          type="date" 
          v-model="to" 
          lang="en" 
          format="yyyy-MM-dd" 
          width=150>
        </date-picker>
      </div>
-->
      <div class="col-sm-6 col-md-3">
        
        {{ $t('message.date') }} : <br>
        <date-picker 
          type="date" 
          v-model="date" 
          lang="en" 
          format="yyyy-MM-dd" 
          range>
        </date-picker>
      <!-- <div class="input">
      <autocomplete
        v-model="searchMachine"
        @selected="onSelectSearch"
        @enter="typeahead"
        @clear="clearSelectedMachine"
        resultsValue = "machineId"
        :source="machineData"></autocomplete>
        </div>   -->
          
          
      </div>
      <div class="col-sm-6 col-md-2">
        <br><b-button v-on:click="search()" variant="primary"><i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}</b-button>
      </div>
    </div>
    <br>
    <b-btn variant="success" @click="showModal()">{{ $t('message.controlMachine') }}</b-btn>
    <br></br>
    <div class="table-responsive">
    <table class="table2" v-if="rowData != ''">
      <thead style="text-align-last: center">
        <tr class="bg-primary">
          <th>ID</th>
          <th>{{ $t('message.machineId') }}</th>
          <th>{{ $t('message.command') }}</th>
          <th>{{ $t('message.status') }}</th>
          <th>{{ $t('message.value') }}</th>
          <th>{{ $t('message.result') }}</th>
          <th>{{ $t('message.createdAt') }}</th>
          <th>{{ $t('message.updatedAt') }}</th>
          <th>{{ $t('message.cancelCommand') }}</th>
<!--            <template v-if="isAdmin(role_id)"><th></th></template>-->
        </tr>
      </thead>
      <tbody style="text-align: center">
        <template v-for="items in rowData">
          <tr>
            <td>{{items.sequence}}</td>  
            <td>{{items.machineId}}</td>  
            <td>{{items.cmd.name}}</td>  
            <td>
                <template v-if="items.status === 'done'">
                    <span class="badge badge-success" style="font-size: 100%">{{items.status}}</span>
                </template>
                <template v-else-if="items.status === 'wait'">
                    <span class="badge badge-secondary" style="font-size: 100%">{{items.status}}</span>
                </template>
                <template v-else>
                    <template v-if="now - new Date(items.updatedAt) >= 3600000">
                        <span class="badge badge-danger" style="font-size: 100%">timeout</span>
                    </template>
                    <template v-else>
                        <span class="badge badge-warning" style="font-size: 100%">{{items.status}}</span>
                    </template>
                </template>
            </td>  
            <td>{{items.value}}</td>  
            <td>{{items.result}}</td>
            <td>{{items.createdAt | moment("YYYY-MM-DD HH:mm")}}</td> 
            <td>{{items.updatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
            <td>
              <b-button variant="danger" :pressed="false" size="sm" @click="deleteCmd(items)" :disabled="items.status !== 'wait'">{{ $t('message.cancel') }}</b-button>
            </td>
          </tr>  
        </template>  
      </tbody>
    </table>
    <template v-else><p style="text-align: center">{{ $t('message.commandNotFound') }}</p></template>
    </div>
    <br>
    <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>  
      
    </b-card>
    <b-modal ref="cancelModal" title="กรุณายืนยันการยกเลิกคำสั่ง" ok-title="ยืนยัน" close-title="ยกเลิก" @ok="cancelCmd">
    <table class="table-modal">
      <tr>
        <td>{{ $t('message.sequnceIdCmd') }} </td>
        <td style="color: red">{{cancelModal.sequence}}</td>
      </tr>
      <tr>
        <td>{{ $t('message.command') }} </td>
        <td style="color: red">{{cancelModal.cmd.name}}</td>
      </tr>
      <tr>
        <td>{{ $t('message.machineId') }} </td>
        <td style="color: red">{{cancelModal.machineId}}</td>
      </tr>
    </table>
    </b-modal>
          
      
    <b-modal ref="CmdModals" hide-header @ok="setCommands()" @cancel="closeModal()"> 
      {{ $t('message.machineId') }}
      <list-select 
        :list="machineData"
        :selected-item="selectedMachine"
        placeholder="Select Machine ID"
        option-value="machineId"
        option-text="name"
        @select="onSelect"
        required
      /><br>
      {{ $t('message.command') }}
      <b-form-select 
        v-model.number="selectedCmd.cmdId"
        :options="commands" 
        text-field="name"
        value-field="cmdId"
        required
      />
      <br></br>
      <template v-if="selectedCmd.cmdId === 5 || selectedCmd.cmdId === 6 || selectedCmd.cmdId === 7">
          <b-form-group
              :label="$t('message.deviceId')"
              label-for="value_slotId"
              description="">
              <b-form-input v-model.number="selectedCmd.value.deviceId" id="value_slotId"></b-form-input>
          </b-form-group>
          <template v-if="selectedCmd.cmdId === 5">
              <b-form-group
                    :label="$t('message.tmpOrTime')"
                    label-for="value_slotId"
                    :description="$t('message.optionEnableCmd')">
                    <b-form-input v-model.number="selectedCmd.value.slotId" id="value_slotId"></b-form-input>
              </b-form-group>
          </template>
      </template>
      <template v-if="selectedCmd.cmdId === 10">
        <b-form-group
          :label="$t('message.url')"
          label-for="value_url"
          >
          <b-form-input v-model.number="selectedCmd.value.url" id="value_url"></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('message.version')"
          label-for="value_version"
          >
          <b-form-input v-model.number="selectedCmd.value.version" id="value_version"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="selectedCmd.cmdId === 11">
        <b-form-group
          :label="$t('message.filterId')"
          label-for="value_filterId"
          >
          <b-form-input v-model.number="selectedCmd.value.filterId" id="value_filterId"></b-form-input>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>
<script>
import webServices from '../../script'
import { ListSelect, MultiListSelect } from '../../custom_modules/search-select'
import { Typeahead } from 'uiv'
import DatePicker from '../../custom_modules/vue2-datepicker'
import moment from 'moment'
export default {
  name: 'commands',
  components: {
    ListSelect,
    DatePicker,
    Typeahead,
    MultiListSelect
  },
  data () {
    return {
      now: new Date(),
      pagination: 1,
      totalRows: 0,
      itemperPage: 20,
      commands: [],
      selectedCmd: {
        value: {}
      },
      selectedMachine: {},
      searchMachine: '',
      machineData: [],
      searchData: {
        page: 1,
        rows: 30,
        machineId: '',
        cmdId: '',
        from: '',
        to: ''
      },
      rowData: '',
      date: [],
      cancelModal: {
        cmd: {}
      },
      typeahead: '',
      selectedCommand: {},
      machineData: [],
      selectedMachine: []
    }
  },
  methods: {
    showModal() {
      this.$refs.CmdModals.show()
    },
    getCmd () {
      webServices.getCommands({machineId: this.selectedCmd.machineId}).then((res) => {
        // console.log(res.data)
        this.commands = res.data
      }).catch((err) => {
        console.log('error @COMMANDS')
        console.log(err)
        this.$toast.error({
          title: 'ERROR',
          message: 'เกิดข้อผิดพลาด'
        }) 
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
      })
    },
    getMachine () {
      webServices.getMachineList(window.localStorage.getItem('userId')).then((res) => {
        this.machineData = res.data
        // console.log(this.machineData)
      }).catch((err) => {
        console.log(err)
        console.log('error @MACHINE')
        this.$toast.error({
          title: 'ERROR',
          message: 'เกิดข้อผิดพลาด'
        }) 
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
      })
    },
    onSelectMachine (items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
    },
    onSelect (item) {
      this.selectedMachine = item
      this.selectedCmd.machineId = item.machineId
      this.getCmd()
    },
    onSelectSearch (item) {
      // console.log(item)
      this.searchMachine = item
      this.searchData.machineId = Number(this.searchMachine.value)
    },
    clearSelectedMachine (item) {
      this.searchData.machineId = ''
    },
    setCommands () {
      this.$Progress.start()
      webServices.setCommands(this.selectedCmd).then((res) => {
        this.$Progress.finish()
        // console.log(res.data)
        if (res.data.success) {
          this.selectedCmd.cmdId = ''
          this.selectedCmd.machineId = ''
          this.selectedCmd.value = {}
          this.selectedMachine = {}
          this.$toast.success({
            title: 'SUCCESS',
            message: 'ส่งคำสั่งสำเร็จ'
          })
          this.search()
        } else {
          // this.selectedCmd.cmdId = ''
          this.selectedCmd.machineId = ''
          this.selectedMachine = {}
          const error = webServices.showError(res.data)
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(error)
          })
        }
      }).catch((err) => {
        this.$Progress.fail()
        console.log(err)
        this.$toast.error({
          title: 'ERROR',
          message: 'เกิดข้อผิดพลาด'
        }) 
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
      })
    },
    closeModal () {
      // this.selectedCmd.cmdId = ''
      this.selectedCmd.machineId = ''
      this.selectedMachine = {}
      this.search()
    },
    getCommandsList () {
      this.$Progress.start()
      webServices.getCommandList(this.searchData).then((res) => {
        this.$Progress.finish()
        // console.log(res.data)
        this.rowData = res.data.rows
        this.totalRows = res.data.count
        this.searchData.from = ''
        this.searchData.to = ''
      }).catch((err) => {
        this.$Progress.fail()
        console.log(err)
        this.$toast.error({
          title: 'ERROR',
          message: 'เกิดข้อผิดพลาด'
        }) 
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
      })
    },
    deleteCmd (cmd) {
      // console.log(cmd)
      this.cancelModal = cmd
      this.$refs.cancelModal.show()
    },
    cancelCmd () {
      webServices.cancelCmd(this.cancelModal.sequence).then((res) => {
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'ยกเลิกคำสั่งสำเร็จ'
          })
          this.cancelModal = {
            cmd: ''
          }
          this.search()
        }
      }).catch((err) => {
        console.log(err)
        console.log('error @cancelCmd')
        this.$toast.error({
          title: 'ERROR',
          message: 'เกิดข้อผิดพลาด'
        }) 
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
      })
    },
    search () {
//      if (this.searchMachine.value) {
//        this.searchData.machineId = this.searchMachine.machineId
//      } else {
//        this.searchData.machineId = this.searchMachine
//      }
      if (this.date.length > 0) {
        var start = this.date[0]
        var end = this.date[1].setHours(23, 59, 59, 999)
        this.searchData.from = moment(start).format('YYYY-MM-DD HH:mm:ss')
        this.searchData.to = moment(end).format('YYYY-MM-DD HH:mm:ss')
        // console.log(this.searchData)
      }
      this.getCommandsList(this.searchData)
    },
    pageChanged (page) {
      this.searchData.page = page
      this.search()
    },
  },
  mounted () {
    this.getMachine()
    this.getCommandsList()
  }
}
</script>